import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 17 17"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "1.5",
      d: "m12.54 9.988 1.706-1.707-1.707-1.707M7.42 8.281h6.78m-5.447 5.296c-2.947 0-5.334-2-5.334-5.334S5.806 2.91 8.753 2.91"
    }, null, -1)
  ])))
}
export default { render: render }